import ReCAPTCHA from 'react-google-recaptcha';
import { useRef } from 'react';

function InvisibleRecaptcha({ className, recaptchaRef }) {
  return (
    <div className={className} style={{ width: '200px' }}>
      <ReCAPTCHA sitekey="6LeIwuQdAAAAAGz4jlmGcUPwcUcaDbI2Bszhezp0" size="invisible" ref={recaptchaRef} badge="bottomleft" />
    </div>
  );
}

export default InvisibleRecaptcha;
