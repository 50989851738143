/**
 * Buttons to be displayed on the floor page beside the SVG. To show Next, Previous and Back buttons
 */

import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import __ from '../../utilities/functions';
import ClickButton from '../../ui/ClickButton';

function Buttons({ floor, position = 'north' }) {
  const dispatch = useDispatch();
  const isLargeAndDown = useMediaQuery({ query: '(max-width : 993px)' });

  // functions to control next, previous and back buttons
  function nextFloor() {
    if (position === 'north') {
      __.redirect(dispatch, `/NorthFloor/${Number(floor) + 1}`);
    }

    if (position === 'south') {
      __.redirect(dispatch, `/SouthFloor/${Number(floor) + 1}`);
    }
  }

  function prevFloor() {
    if (position === 'north') {
      __.redirect(dispatch, `/NorthFloor/${Number(floor) - 1}`);
    }

    if (position === 'south') {
      __.redirect(dispatch, `/SouthFloor/${Number(floor) - 1}`);
    }
  }

  function goBack() {
    if (position === 'north') {
      __.redirect(dispatch, '/NorthBuilding');
    }

    if (position === 'south') {
      __.redirect(dispatch, '/SouthBuilding');
    }
  }
  return (
    <Fragment>
      <i
        id="takeBack"
        className="medium material-icons o-70"
        onClick={() => {
          goBack();
        }}
      >
        keyboard_backspace
      </i>
      {/* eslint-disable-next-line no-nested-ternary */}
      {floor < 6 ? (
        isLargeAndDown ? (
          <ClickButton
            onClick={() => {
              nextFloor();
            }}
            className=" right ma2"
          >
            Next Floor
          </ClickButton>
        ) : (
          <i
            id="floorSwitchForwardDesktop"
            className="medium material-icons grow-large o-70"
            onClick={() => {
              nextFloor();
            }}
          >
            keyboard_arrow_right
          </i>
        )
      ) : null}

      {/* eslint-disable-next-line no-nested-ternary */}
      {floor > 1 ? (
        isLargeAndDown ? (
          <ClickButton
            onClick={() => {
              prevFloor();
            }}
            className="right ma2"
          >
            Previous Floor
          </ClickButton>
        ) : (
          <i
            id="floorSwitchBackDesktop"
            className="medium material-icons grow-large o-70"
            onClick={() => {
              prevFloor();
            }}
          >
            keyboard_arrow_left
          </i>
        )
      ) : null}
    </Fragment>
  );
}

export default Buttons;
