/**
 * landing file for the floor page, displays either desktop or mobile version depending on the size of the screen.
 */

import { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import Sidebar from '../../view/components/Sidebar';
import FloorNav from '../navs/Floor';
import InnerHeader from '../../view/sections/InnerHeader';
import Footer from '../../view/sections/Footer';
import Buttons from '../components/Buttons';
import Analytics from '../../Analytics/Analytics';
import Floor from '../svg/Floor';
import FloorDescriptor from '../../Building/components/FloorDescriptor';

function Floors({ position }) {
  useEffect(() => {
    new Analytics().trackPageview('NorthFloor Page');
  });
  const { floor } = useParams();

  return (
    <Fragment>
      <InnerHeader />
      <div id="floorSVG" className="mb6">
        <Floor floorNumber={floor} position={position} />
        <Sidebar collapsible={true}>
          <FloorNav floor={floor} position={_.upperFirst(position)} />
        </Sidebar>
        <FloorDescriptor />
        <Buttons floor={floor} position={position} />
      </div>
      <Footer />
    </Fragment>
  );
}

export default Floors;
