import { Fragment, useEffect } from 'react';
import M from 'materialize-css';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import InnerHeader from '../../view/sections/InnerHeader';
import Footer from '../../view/sections/Footer';
import Analytics from '../../Analytics/Analytics';
import Nav from '../navs/Nav';
import Sidebar from '../../view/components/Sidebar';
import beacons from '../data/beacons.json';

function Tours() {
  const { type = 'type_h_1', mode } = useParams();
  useEffect(() => {
    M.Collapsible.init($('.collapsible'));
  }, []);

  useEffect(() => {
    new Analytics().trackPageview('Building Page');
  });

  const details = beacons.find((beacon) => beacon.suiteType === type);

  if (mode === 'embed') {
    localStorage.setItem('embedMode', 'yes');
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={details.viewBox} className="tablet-responsive mt4 svg-content">
        <image className="absolute overflow-auto svg-position" x={details.x} y={details.y} href={details.image} />
        {details?.beacons?.map((beacon, i) => (
          <NavLink to={beacon.route} key={i}>
            <ellipse cx={beacon.x} cy={beacon.y} rx="18" ry="18" className="beacon" />
          </NavLink>
        ))}
      </svg>
    );
  }

  localStorage.removeItem('embedMode');

  return (
    <Fragment>
      <InnerHeader />
      <div className="stage-1" id="stage1">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={details.viewBox} className="tablet-responsive mt4 svg-content">
          <image className="absolute overflow-auto svg-position" x={details.x} y={details.y} href={details.image} />
          {details?.beacons?.map((beacon, i) => (
            <NavLink to={beacon.route} key={i}>
              <ellipse cx={beacon.x} cy={beacon.y} rx="18" ry="18" className="beacon" />
            </NavLink>
          ))}
        </svg>
        <Sidebar collapsible={true}>
          <Nav />
        </Sidebar>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Tours;
