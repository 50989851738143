import { useEffect, useState } from 'react';
import $ from 'jquery';

function Sidebar({ children, collapsible, id = 'page-wrap', className, collapsedByDefault = false }) {
  // if in embed mode, dont show sidebar
  // if (sessionStorage.getItem('embedMode') === 'yes') {
  //   return <></>;
  // }

  const [sidenavVisible, setSidenavVisible] = useState(true);

  function toggleSidenav() {
    // check if sidenav is open or close
    if (sidenavVisible) {
      $(`#${id}`).css('transform', 'translateX(-90%)');
      $('#slider-arrow').html('arrow_back_ios');
      $('.sideBarContent').hide();
      setSidenavVisible(false);
    } else {
      $(`#${id}`).css('transform', 'translateX(0%)');
      // $('#slider-arrow').addClass('rotate-180');
      $('#slider-arrow').html('clear');
      $('.sideBarContent').show();
      setSidenavVisible(true);
    }
  }

  useEffect(() => {
    if (collapsedByDefault) {
      $('.sideBarContent').hide();
    }
  }, []);

  return (
    <div className={`sidenav ${className}`} id={id} style={{ position: 'absolute' }}>
      {collapsible ? (
        <a
          href="#"
          onClick={() => {
            toggleSidenav();
          }}
        >
          <i className="small black-text arrow_back rotate-180 material-icons z-999" id="slider-arrow">
            clear
          </i>
        </a>
      ) : null}

      <div id="sideBarContent">{children}</div>
    </div>
  );
}

export default Sidebar;
