import PropTypes from 'prop-types';

function Submit({ children, className, position = 'left' }) {
  return (
    <div className={`input-field ${className}`}>
      <button className={`f4 mt3 link dim ba bw1 ph3 pv2 mb2 dib dark-blue-text btn_blue waves-effect waves-dark-blue  ${position}`} type="submit">
        {children}
      </button>
    </div>
  );
}

Submit.propTypes = {
  className: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['left', 'right']),
};

export default Submit;
