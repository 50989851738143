function Spacer({ className }) {
  return (
    <div className={`container-fluid ${className}`}>
      <div className="row no-margin-bottom">
        <div className="col s12 lookout-bg h3" />
      </div>
    </div>
  );
}

export default Spacer;
