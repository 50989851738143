/**
 * file contains the content of the sidebar for the building profile page
 * also contains the logic for the availability slider
 */
import { NavLink } from 'react-router-dom';

import { useEffect, useState } from 'react';
import M from 'materialize-css';
import $ from 'jquery';
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import { useSelector } from 'react-redux';
import Sidebar from '../../view/components/Sidebar';

function Buildings({ setHover }) {
  const state = useSelector((store) => store);

  useEffect(() => {
    M.Collapsible.init($('.collapsible'));
  }, []);

  return (
    <div>
      <div className="row mt5-ns mt4">
        <div className="col flex flex-column center">
          <img className="pa1p ma1 w-80 center hide-on-med-and-down" src="https://storage.googleapis.com/eaglecrest-public/10776TL_Logo_Colour_Full-1.png" alt="logo" />
          <p className="f4 pt3 measure" style={{ textAlign: 'justify' }}>
            {state.content.homepage_sidebar_text}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m12 l12 xl12 no-pd-hz f4 tj">
          <ul className="collapsible expandable">
            <NavLink
              to="NorthBuilding"
              onMouseOver={() => {
                setHover('north');
                $('#northButton').addClass('active');
              }}
              onMouseOut={() => {
                setHover('none');
                $('#northButton').removeClass('active');
              }}
            >
              <li className="building-collapsible" id="northButton">
                <div className="collapsible-header border">
                  <div className="flex">
                    <p className="pl3">North Building</p>
                  </div>
                </div>
              </li>
            </NavLink>
            <NavLink
              to="SouthBuilding"
              onMouseOver={() => {
                setHover('south');
                $('#southButton').addClass('active');
              }}
              onMouseOut={() => {
                setHover('none');
                $('#southButton').removeClass('active');
              }}
            >
              <li className="building-collapsible" id="southButton">
                <div className="collapsible-header border">
                  <div className="flex">
                    <p className="pl3">South Building</p>
                  </div>
                </div>
              </li>
            </NavLink>
            <NavLink to="Tour">
              <li className="building-collapsible" id="southButton">
                <div className="collapsible-header border">
                  <div className="flex">
                    <p className="pl3">360&#176; Suite Tours</p>
                  </div>
                </div>
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Buildings;
