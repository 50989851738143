/**
 * landing file for the building profile page
 */

import { Fragment, useEffect, useState } from 'react';
import M from 'materialize-css';
import $ from 'jquery';
import InnerHeader from '../../view/sections/InnerHeader';
import Footer from '../../view/sections/Footer';
import DNorthFloorsSVG from '../svgs/desktop/NorthFloors';
import Sidebar from '../../view/components/Sidebar';
import FloorsNav from '../navs/Floors';
import Analytics from '../../Analytics/Analytics';
import BackButton from '../components/BackButton';
import BuildingNavs from '../navs/Buildings';
import DBuildingSVG from '../svgs/desktop/Building';
import DSouthFloorsSVG from '../svgs/desktop/SouthFloors';

function Building({ homepage = false, position }) {
  useEffect(() => {
    M.Collapsible.init($('.collapsible'));
  }, []);

  useEffect(() => {
    new Analytics().trackPageview('Building Page');
  });

  const [hover, setHover] = useState('none');

  /**
   * determine what building to display based on position provided. Either 'north' or 'south'
   * @param homepage
   * @param position
   * @returns {JSX.Element}
   */
  const getBuilding = (homepage, position) => {
    if (position === 'north') {
      return (
        <>
          {homepage ? <DBuildingSVG position="north" hover={hover} /> : <DNorthFloorsSVG hover={hover} />}
          <Sidebar collapsible={true}>{homepage ? <BuildingNavs setHover={setHover} /> : <FloorsNav position="north" setHover={setHover} />}</Sidebar>
        </>
      );
    }

    if (position === 'south') {
      return (
        <>
          {homepage ? <DBuildingSVG position="south" hover={hover} /> : <DSouthFloorsSVG hover={hover} />}
          <Sidebar collapsible={true}>{homepage ? <BuildingNavs setHover={setHover} /> : <FloorsNav position="south" setHover={setHover} />}</Sidebar>
        </>
      );
    }
  };

  return (
    <Fragment>
      <InnerHeader />
      <div id="stage1">{getBuilding(homepage, position)}</div>
      {!homepage ? <BackButton /> : null}
      <Footer />
    </Fragment>
  );
}

export default Building;
