import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import pannellum from './libs/pannellum.js';
import './css/pannellum.css';
import Button from '../ui/Button';

function Pannellum({ id, config }) {
  const history = useHistory();
  function goBack() {
    history.goBack();
  }

  const [instance, setInstance] = useState();
  useEffect(() => {
    setInstance(pannellum.viewer(document.querySelector(`#${id}`), config));
  }, []);

  function Controls() {
    return (
      <Fragment>
        <i
          id="vrtakeBacksuite"
          className="medium material-icons o-70"
          onClick={() => {
            goBack();
          }}
        >
          keyboard_backspace
        </i>
        <div className="controls-new">
          <a
            className="ctrl grow-large"
            id="pan-up"
            onClick={() => {
              instance.setPitch(instance.getPitch() + 20);
            }}
          >
            <i className="material-icons small grow-large">keyboard_arrow_up</i>
          </a>
          <a
            className="ctrl grow-large"
            id="pan-down"
            onClick={() => {
              instance.setPitch(instance.getPitch() - 20);
            }}
          >
            <i className="material-icons small grow-large">keyboard_arrow_down</i>
          </a>
          <a
            className="ctrl grow-large"
            id="pan-left"
            onClick={() => {
              instance.setYaw(instance.getYaw() - 20);
            }}
          >
            <i className="material-icons small grow-large">keyboard_arrow_left</i>
          </a>

          <a
            className="ctrl grow-large"
            id="pan-right"
            onClick={() => {
              instance.setYaw(instance.getYaw() + 20);
            }}
          >
            <i className="material-icons small grow-large">keyboard_arrow_right</i>
          </a>

          <a
            className="ctrl grow-large"
            id="zoom-in"
            onClick={() => {
              instance.setHfov(instance.getHfov() - 20);
            }}
          >
            <i className="material-icons grow-large small">zoom_in</i>
          </a>
          <a
            className="ctrl grow-large"
            id="zoom-out"
            onClick={() => {
              instance.setHfov(instance.getHfov() + 30);
            }}
          >
            <i className="material-icons grow-large small">zoom_out</i>
          </a>
          {/* <a */}
          {/*  className="ctrl grow-large" */}
          {/*  id="fullscreen" */}
          {/*  onClick={() => { */}
          {/*    instance.setPitch(instance.toggleFullscreen()); */}
          {/*  }} */}
          {/* > */}
          {/*  <i className="material-icons grow-large small">fullscreen</i> */}
          {/* </a> */}
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <div id={id} className="vr-height" />
      <Controls />
    </div>
  );
}

export default Pannellum;
