import { Fragment, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import CoreUtils from 'groupcore-utils';
import { useDispatch } from 'react-redux';
import Spacer from '../../view/components/Spacer';
import Footer from '../../view/sections/Footer';
import InnerHeader from '../../view/sections/InnerHeader';
import Analytics from '../../Analytics/Analytics';
import TextInput from '../../ui/TextInput';
import __ from '../../utilities/functions';
import config from '../../utilities/config';
import InvisibleRecaptcha from '../../ui/InvisibleRecaptcha';
import Submit from '../../ui/Submit';

function ApplicationPage() {
  useEffect(() => {
    new Analytics().trackPageview('Application Page');
  });
  const { building, suite } = useParams();

  const [form, setForm] = useState({});
  const dispatch = useDispatch();

  // code to handle form submisssion
  const recaptchaRef = useRef();

  async function sendForm(e) {
    e.preventDefault();
    const recaptchaToken = await recaptchaRef.current.executeAsync();
    if (recaptchaToken) {
      __.showLoadingBar(dispatch);

      if (!form.recaptcha) {
        __.hideLoadingBar(dispatch);
        __.displayErrorMessage('Please confirm you are not a robot, to proceed!');
        return;
      }

      const coreUtil = new CoreUtils({ projectId: config.projectId, apiToken: config.apiToken, apiUrl: config.apiUrl });
      try {
        // add a lead
        const addLead = await coreUtil.addLead(form);
        await coreUtil.submitForm({ formData: { ...form, building, suite, recaptcha: 'passed' }, formId: 26, email: form.email, leadId: addLead.data.insertId });

        __.hideLoadingBar(dispatch);
        __.displaySuccessMessage('Your form has been sent successfully! We will get back to you shortly. Thank you!!');
        __.redirect(dispatch, '/');
      } catch (err) {
        console.log('caught error', err.response.data.message);
      }
    }
  }

  return (
    <Fragment>
      <InnerHeader />
      <Spacer className="sidepadding" />

      <div className="col s12 no-margin-top">
        <div className="row">
          <div className="col s12 m12 l12 xl12 pl1">
            <h1 className="dark-blue-text ma0 lh-title">
              Condo Reservation
              <br />({building} - {suite})
            </h1>
            <p className="dark-blue-text f4 lh-copy futura-light no-margin pl0 pt5">Thank you for your interest in our condos. Please fill out the form below and we will contact you.</p>
          </div>
          <div className="col s12 l8 offset-l2 padding-l2-m">
            <form
              onSubmit={(e) => {
                sendForm(e);
              }}
            >
              <TextInput label="First Name*" className="col s12 m6 l3 pa2" state={form} isRequired id="firstname" setState={setForm} type="text" value={form?.firstname} />
              <TextInput label="Last Name*" className="col s12 m6 l3 pa2" state={form} isRequired id="lastname" setState={setForm} type="text" value={form?.lastname} />
              <TextInput label="Email*" className="col s12 l3 m6 pa2" state={form} id="email" setState={setForm} type="text" isRequired value={form?.email} />
              <TextInput label="Phone*" className="col s12 l3 m6 pa2" state={form} id="phone" setState={setForm} type="text" isRequired value={form?.phone} />

              <TextInput label="Message" className="col s12 pa2" state={form} id="message" setState={setForm} type="text" isRequired={false} value={form?.message} />

              <InvisibleRecaptcha className="col s12" recaptchaRef={recaptchaRef} />

              <Submit className="col s12" position="right">
                Send
              </Submit>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
}

export default ApplicationPage;
