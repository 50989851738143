import { Fragment, useEffect } from 'react';
import M from 'materialize-css';
import $ from 'jquery';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import InnerHeader from '../../view/sections/InnerHeader';
import Footer from '../../view/sections/Footer';
import Analytics from '../../Analytics/Analytics';
import Nav from '../navs/Nav';
import Sidebar from '../../view/components/Sidebar';
import pannellumData from '../data/pannellum.json';
import Pannellum from '../../Pannellum/Pannellum';

function Vr() {
  const { type = 'type_h_1', scene, mode } = useParams();
  useEffect(() => {
    M.Collapsible.init($('.collapsible'));
  }, []);

  useEffect(() => {
    new Analytics().trackPageview('Building Page');
  });

  let config = pannellumData.find((data) => data.suiteType === type);
  if (!config) {
    [config] = pannellumData;
  }

  // set the first scene to the beacon that was clicked
  config.default.firstScene = scene;

  if (mode === 'embed') {
    localStorage.setItem('embedMode', 'yes');
    return <Pannellum id="any" config={config} />;
  }

  if (localStorage.getItem('embedMode') === 'yes') {
    return <Pannellum id="any" config={config} />;
  }

  localStorage.removeItem('embedMode');

  return (
    <Fragment>
      <InnerHeader />
      <div id="stage1">
        <Pannellum id="any" config={config} />
        <Sidebar collapsible={true}>
          <Nav />
        </Sidebar>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Vr;
