import { createStore } from 'redux';
import __ from './functions';

// initial state
const initialState = {
  loggedIn: false,
  loading: false,
  userId: null,
  redirect: { status: false, destination: false },
  active_menu: 0,
  userDetails: {},
  content: {},
};

// reducer pure function
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AUTH':
      state = {
        ...state,
        loggedIn: action.payload.status,
        userId: action.payload.userId,
        userType: action.payload.userType,
        firstName: action.payload.firstName,
        email: action.payload.email,
      };
      break;

    case 'LOGOUT':
      window.localStorage.clear();
      state = { ...state, loggedIn: false, loading: false };
      __.displaySuccessMessage('You have successfully logged out!');
      break;

    case 'SET_LOADING':
      state = { ...state, loading: action.payload.value };
      break;

    case 'REDIRECT':
      state = { ...state, redirect: action.payload };
      break;

    case 'CONTENT':
      state = { ...state, content: action.payload };
      break;

    default:
      return state;
  }
  return state;
};

// create redux store
// export const store = createStore(reducer, applyMiddleware(createLogger));
export const store = createStore(reducer);
