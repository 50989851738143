import { NavLink } from 'react-router-dom';

function InnerHeader() {
  // if in embed mode, dont show inner header
  if (sessionStorage.getItem('embedMode') === 'yes') {
    return <></>;
  }

  return (
    <nav>
      <div className="nav-wrapper z-max">
        <div id="navbar">
          <div className="wrapper white-bg">
            <div className="nav-wrapper">
              <div className="row mb0">
                <div className="col s12 m12 l12 xl12 center-align" style={{ margin: '1rem' }}>
                  <NavLink to="/">
                    <img className="pa1 ma1" width="200px" src="https://storage.googleapis.com/eaglecrest-public/10776TL_Logo_Colour_Full-1.png" alt="logo" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
export default InnerHeader;
