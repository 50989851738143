/**
 * file contains the content of the sidebar for the building profile page
 * also contains the logic for the availability slider
 */
import { NavLink } from 'react-router-dom';

import { useEffect } from 'react';
import M from 'materialize-css';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import Sidebar from '../../view/components/Sidebar';

function Floors({ position, setHover }) {
  const state = useSelector((store) => store);

  useEffect(() => {
    M.Collapsible.init($('.collapsible'));
  }, []);

  return (
    <div>
      <div className="row mt2 hide-on-med-and-down">
        <div className="col flex flex-column center">
          <img className="pa1p ma1 w-80 center" src="https://storage.googleapis.com/eaglecrest-public/10776TL_Logo_Colour_Full-1.png" alt="logo" />
          <p className="f4 pt3 measure hide" style={{ textAlign: 'justify' }}>
            {state.content.homepage_sidebar_text}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m12 l12 xl12 no-pd-hz f4 tj">
          <NavLink
            to={position === 'north' ? '/NorthFloor/6' : '/SouthFloor/6'}
            onMouseOver={() => {
              $('#floorBtn6').addClass('active');
              setHover('6');
            }}
            onMouseOut={() => {
              $('#floorBtn6').removeClass('active');
              setHover('none');
            }}
          >
            <div className="building-collapsible collapsible-header border mv3" id="floorBtn6">
              <div className="flex">
                <p className="pl5-xl pv1">Sixth floor availability</p>
              </div>
            </div>
          </NavLink>

          <NavLink
            to={position === 'north' ? '/NorthFloor/5' : '/SouthFloor/5'}
            onMouseOver={() => {
              $('#floorBtn5').addClass('active');
              setHover('5');
            }}
            onMouseOut={() => {
              $('#floorBtn5').removeClass('active');
              setHover('none');
            }}
          >
            <div className="building-collapsible collapsible-header border mv3" id="floorBtn5">
              <div className="flex">
                <p className="pl5-xl pv1">Fifth floor availability</p>
              </div>
            </div>
          </NavLink>

          <NavLink
            to={position === 'north' ? '/NorthFloor/4' : '/SouthFloor/4'}
            onMouseOver={() => {
              $('#floorBtn4').addClass('active');
              setHover('4');
            }}
            onMouseOut={() => {
              $('#floorBtn4').removeClass('active');
              setHover('none');
            }}
          >
            <div className="building-collapsible collapsible-header border mv3" id="floorBtn4">
              <div className="flex">
                <p className="pl5-xl pv1">Fourth floor availability</p>
              </div>
            </div>
          </NavLink>

          <NavLink
            to={position === 'north' ? '/NorthFloor/3' : '/SouthFloor/3'}
            onMouseOver={() => {
              $('#floorBtn3').addClass('active');
              setHover('3');
            }}
            onMouseOut={() => {
              $('#floorBtn3').removeClass('active');
              setHover('none');
            }}
          >
            <div className="building-collapsible collapsible-header border mv3" id="floorBtn3">
              <div className="flex">
                <p className="pl5-xl pv1">Third floor availability</p>
              </div>
            </div>
          </NavLink>

          <NavLink
            to={position === 'north' ? '/NorthFloor/2' : '/SouthFloor/2'}
            onMouseOver={() => {
              $('#floorBtn2').addClass('active');
              setHover('2');
            }}
            onMouseOut={() => {
              $('#floorBtn2').removeClass('active');
              setHover('none');
            }}
          >
            <div className="building-collapsible collapsible-header border mv3" id="floorBtn2">
              <div className="flex">
                <p className="pl5-xl pv1">Second floor availability</p>
              </div>
            </div>
          </NavLink>

          <NavLink
            to={position === 'north' ? '/NorthFloor/1' : '/SouthFloor/1'}
            onMouseOver={() => {
              $('#floorBtn1').addClass('active');
              setHover('1');
            }}
            onMouseOut={() => {
              $('#floorBtn1').removeClass('active');
              setHover('none');
            }}
          >
            <div className="building-collapsible collapsible-header border mv3" id="floorBtn1">
              <div className="flex">
                <p className="pl5-xl pv1">First floor availability</p>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Floors;
