/**
 * desktop version of the South building SVG with colored Building overlay
 */
import { NavLink } from 'react-router-dom';
import { Fragment, useState } from 'react';

function Building({ position, hover }) {
  let northPathClass = 'no-fill';
  let southPathClass = 'no-fill';

  if (hover === 'north') {
    northPathClass = 'building-svg-path';
  }

  if (hover === 'south') {
    southPathClass = 'building-svg-path';
  }

  return (
    <Fragment>
      <div className="white-bg hide-on-small-and-down tablet-height" id="backgroundSVG">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1130" className="tablet-responsive">
          <image className="absolute overflow-auto" x="0" y="-80" href="https://storage.googleapis.com/eaglecrest-public/10498LookOut_BearMountain_034%20(1).jpg" />
          <NavLink to="NorthBuilding">
            <path
              className={northPathClass}
              d="M 463.577 962.96 L 453.083 646.624 L 494.264 653.654 L 489.242 531.118 L 525.4 519.065 L 495.268 514.043 L 972.527 373.152 L 1002.239 375.982 L 1012.143 372.091 L 1011.79 349.807 L 1043.271 339.902 L 1095.621 343.44 L 1094.56 369.968 L 1234.553 378.104 L 1232.313 481.979 L 1253.537 483.748 L 1253.537 494.713 L 1210.516 519.768 L 1210.516 528.738 L 1198.47 528.226 L 1198.214 558.212 L 1306.462 568.451 L 1307.572 626.183 L 866.807 928.785 L 816.6 933.041 L 726.115 979.116 L 681.15 992.994 L 463.577 962.96 Z"
            />
          </NavLink>

          <NavLink to="SouthBuilding">
            <path
              className={southPathClass}
              d="M 1176.338 373.193 L 1176.338 355.956 L 1164.105 355.585 L 1164.29 347.059 L 1175.782 342.611 L 1176.338 335.012 L 1196.17 327.783 L 1216.558 328.71 L 1324.451 293.391 L 1342.8 294.318 L 1349.473 291.908 L 1371.531 292.65 L 1371.531 287.46 L 1390.992 280.046 L 1473.472 282.641 L 1473.657 297.654 L 1486.075 297.469 L 1486.075 301.176 L 1504.054 301.176 L 1501.274 356.224 L 1493.674 360.672 L 1492.562 397.906 L 1571.913 394.837 L 1570.059 415.04 L 1465.585 505.736 L 1340.847 483.68 L 1292.586 513.692 L 1216.46 508.859 L 1228.342 506.643 L 1252.71 491.337 L 1252.509 484.289 L 1232.369 482.073 L 1234.786 377.461 L 1176.338 373.193 Z"
            />
          </NavLink>
        </svg>
      </div>

      <div className="white-bg hide-on-med-and-up tablet-height" id="backgroundSVG">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720 1280" className="tablet-responsive">
          <image className="absolute overflow-auto" x="0" y="-80" href="https://storage.googleapis.com/eaglecrest-public/10317Layer%202.jpg" />
          <NavLink to="NorthBuilding">
            <path
              className={northPathClass}
              d="M 1.252 494.858 L 106.59 463.764 L 142.126 465.668 L 151.962 461.861 L 152.279 435.526 L 187.18 425.373 L 251.271 428.546 L 251.906 460.274 L 419.608 469.475 L 417.07 596.001 L 440.513 598.046 L 440.831 608.199 L 414.179 622.159 L 399.901 621.525 L 389.113 628.505 L 389.431 651.349 L 377.691 650.715 L 377.374 685.299 L 499.563 696.65 L 500.198 705.534 L 506.544 705.217 L 507.178 740.118 L 506.544 762.257 L -0.772 1108.798"
            />
          </NavLink>

          <NavLink to="SouthBuilding">
            <path
              className={southPathClass}
              d="M 349.898 464.8 L 350.115 443.972 L 336.555 443.213 L 336.23 431.606 L 350.657 427.05 L 351.199 416.854 L 373.654 409.694 L 399.471 410.984 L 528.653 367.702 L 551.324 367.955 L 557.074 365.243 L 584.012 365.701 L 584.012 361.145 L 607.876 352.226 L 708.797 355.656 L 707.862 372.69 L 720.21 372.793 L 719.945 606.286 L 546.935 593.771 L 487.516 633.676 L 424.807 627.318 L 415.378 633.238 L 390.163 632.799 L 390.163 629.291 L 400.172 622.517 L 413.154 623.266 L 441.365 608.661 L 441.116 597.301 L 418.147 594.93 L 420.519 468.561 L 349.898 464.8 Z"
            />
          </NavLink>
        </svg>
      </div>
    </Fragment>
  );
}

export default Building;
