import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function ClickButton({ href, icon, children, position = 'left', className = '', modalInstance = false, onClick = () => {} }) {
  return (
    <a
      href={href}
      className={`f4 mt3 link dim ba bw1 ph3 pv2 mb2 dib black-text btn_blue waves-effect waves-dark-blue ${position} ${className}`}
      // close modal if this button was located on a modal. Bugfix (Issue: LK-17)
      onClick={() => {
        if (modalInstance) {
          modalInstance[0].close();
        }

        return onClick();
      }}
    >
      <i className="material-icons md-36">{icon}</i> {children}
    </a>
  );
}

ClickButton.propTypes = {
  children: PropTypes.string.isRequired,
};

export default ClickButton;
