/**
 * sidebar content for the floors page
 */

import { Fragment } from 'react';
import 'nouislider/distribute/nouislider.css';
import { useSelector } from 'react-redux';

function FloorNav({ floor, position }) {
  const state = useSelector((store) => store);

  // to convert the floor numbers to names like 1 to first
  let floorName;
  switch (floor) {
    case '6':
      floorName = 'Sixth';
      break;

    case '5':
      floorName = 'Fifth';
      break;

    case '4':
      floorName = 'Fourth';
      break;

    case '3':
      floorName = 'Third';
      break;

    case '2':
      floorName = 'Second';
      break;

    case '1':
      floorName = 'First';
      break;

    default:
      floorName = 'Unknown';
  }

  return (
    <Fragment>
      <div className="row mt5-ns mt4">
        <div className="col">
          <h1 className="f2 lh-title mb2">{position} Building</h1>
          <h1 className="f3 lh-title mb2">{floorName} Floor</h1>
          <h1 className="f3 lh-title mb4 mt0">Suite Availability</h1>
          <p className="f4 measure "> {state.content.suite_availability_text}</p>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m12 l12 xl12 no-pd-hz f4 tj">
          <ul className="collapsible expandable">
            <li className="coll pa-0">
              <div className="collapsible-header dark-blue-text available">
                <div className="flex">
                  <h6 className="pa3">condo available</h6>
                </div>
              </div>
            </li>
            <li className="coll pa-0">
              <div className="collapsible-header white-text reserved">
                <div className="flex">
                  <h6 className="pa3">condo on hold</h6>
                </div>
              </div>
            </li>
            <li className="coll pa-0">
              <div className="collapsible-header white-text rented">
                <div className="flex">
                  <h6 className="pa3">condo sold </h6>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
}

export default FloorNav;
