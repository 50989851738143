import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import LoadingOverlay from 'react-loading-overlay';
import { useEffect } from 'react';
import CoreUtils from 'groupcore-utils';
import ScrollToTop from './utilities/ScrollToTop';
import MyRedirect from './utilities/redirect';
import Building from './Building/pages/Building';
import Floors from './Floor/pages/Floors';
import __ from './utilities/functions';
import ReservePage from './Reserve/pages/Reserve';
import config from './utilities/config';
import Tour from './Tours/pages/Tour';
import Vr from './Tours/pages/Vr';

function App() {
  const state = useSelector((store) => store);
  const dispatch = useDispatch();

  // get content from the core
  useEffect(() => {
    __.showLoadingBar(dispatch);
    const coreUtil = new CoreUtils({ projectId: config.projectId, apiToken: config.apiToken, apiUrl: config.apiUrl });
    coreUtil.getSectionContent({ groupId: 2 }).then((response) => {
      __.setContent(dispatch, response);
      __.hideLoadingBar(dispatch);
    });
  }, []);

  // get embed param here and set session storage if embed param present
  // eslint-disable-next-line no-restricted-globals
  const presentLocation = location.href;
  if (presentLocation.match('embed')) {
    sessionStorage.setItem('embedMode', 'yes');
    // eslint-disable-next-line no-restricted-globals
    location.replace(presentLocation.replace('embed', ''));
  }
  return (
    <BrowserRouter>
      <ScrollToTop>
        <LoadingOverlay active={state.loading} spinner={true}>
          <div className="container-fluid">
            <div className="row">
              <Route path="/NorthBuilding" exact render={() => <Building position="north" />} />
              <Route path="/SouthBuilding" exact render={() => <Building position="south" />} />
              <Route path="/NorthFloor/:floor" exact render={() => <Floors position="north" />} />
              <Route path="/SouthFloor/:floor" exact render={() => <Floors position="south" />} />
              <Route path="/Reserve/:building/:suite" exact component={ReservePage} />
              <Route path="/Tour/:type/:mode" exact component={Tour} />
              <Route path="/Tour/:type" exact component={Tour} />
              <Route path="/Tour" exact component={Tour} />
              <Route path="/Vr/:type/:scene/:mode" exact component={Vr} />
              <Route path="/Vr/:type/:scene" exact component={Vr} />
              <Route path="/" exact render={() => <Building homepage={true} position="north" />} />
            </div>
          </div>
        </LoadingOverlay>
        <NotificationContainer />
        {/* {state.loading ? <Loader /> : null} */}
        {state.redirect.status ? <MyRedirect /> : ''}
      </ScrollToTop>
      <ReactTooltip backgroundColor="#1c2b4a" html={true} />
    </BrowserRouter>
  );
}

export default App;
