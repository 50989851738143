import { Fragment } from 'react';

function FloorDescriptor() {
  return (
    <Fragment>
      <p className="absolute topDescriptor hide-on-med-and-down f2 floorDescriptor z-2">Florence Lake View</p>
      <p className="absolute bottomDescriptor hide-on-med-and-down floorDescriptor f2 z-2">Promenade View</p>
    </Fragment>
  );
}

export default FloorDescriptor;
