import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function ModalButton({ modalId, icon, className = '', tooltip, children, onClick = null }) {
  return (
    <a href={`#${modalId}`} className={`modal-trigger ${className}`} onClick={onClick}>
      {children}
    </a>
  );
}

export default ModalButton;
