import { NavLink } from 'react-router-dom';

import { useEffect } from 'react';
import M from 'materialize-css';
import $ from 'jquery';
import 'nouislider/distribute/nouislider.css';

function Nav() {
  useEffect(() => {
    M.Collapsible.init($('.collapsible'));
  }, []);

  return (
    <div>
      <div className="row mt2-ns mt4 hide-on-med-and-down">
        <div className="col flex flex-column center">
          <img className="pa1p ma1 w-80 center " src="https://storage.googleapis.com/eaglecrest-public/10776TL_Logo_Colour_Full-1.png" alt="logo" />
        </div>
      </div>
      <div className="row mt4">
        <div className="col s12 m12 l12 xl12 no-pd-hz f4 tj">
          <ul className="collapsible expandable">
            <NavLink to="/Tour/type_h_1">
              <li className="building-collapsible" id="northButton">
                <div className="collapsible-header border ph3">
                  <div className="flex">
                    <p className="pl3">Suite Type H in the Peak</p>
                  </div>
                </div>
              </li>
            </NavLink>
            <NavLink to="/Tour/type_h_2">
              <li className="building-collapsible" id="southButton">
                <div className="collapsible-header border">
                  <div className="flex">
                    <p className="pl3">Suite Type H in the Drift</p>
                  </div>
                </div>
              </li>
            </NavLink>
            <NavLink to="/Tour/type_h_3">
              <li className="building-collapsible" id="southButton">
                <div className="collapsible-header border">
                  <div className="flex">
                    <p className="pl3">Suite Type H Un-furnished</p>
                  </div>
                </div>
              </li>
            </NavLink>
            <NavLink to="/Tour/type_d_2">
              <li className="building-collapsible" id="northButton">
                <div className="collapsible-header border">
                  <div className="flex">
                    <p className="pl3">Suite Type D in the Peak</p>
                  </div>
                </div>
              </li>
            </NavLink>
            <NavLink to="/Tour/type_d_1">
              <li className="building-collapsible" id="southButton">
                <div className="collapsible-header border">
                  <div className="flex">
                    <p className="pl3">Suite Type D in the Drift</p>
                  </div>
                </div>
              </li>
            </NavLink>
            <NavLink to="/Tour/type_d_3">
              <li className="building-collapsible" id="southButton">
                <div className="collapsible-header border">
                  <div className="flex">
                    <p className="pl3">Suite Type D Un-furnished</p>
                  </div>
                </div>
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Nav;
