import React, { useEffect, useState, Fragment } from 'react';
import M from 'materialize-css';
import $ from 'jquery';

const Modal = ({ id, children, setInstance }) => {
  // modal
  useEffect(() => {
    const instance = M.Modal.init($('.modal'), { opacity: 0.7, inDuration: 4000 });

    // update modal instance in parent component
    setInstance(instance);
  }, []);

  return (
    <div id={id} className="modal">
      <p className="ma3 right">
        <i className="material-icons modal-close">close</i>
      </p>
      <div className="modal-content">{children}</div>
    </div>
  );
};

export default Modal;
