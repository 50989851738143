/**
 * desktop version of the South Building SVG with colored floor overlays
 */

import { NavLink } from 'react-router-dom';
import { Fragment } from 'react';

function SouthFloors({ hover }) {
  let floor1Active = 'no-fill';
  let floor2Active = 'no-fill';
  let floor3Active = 'no-fill';
  let floor4Active = 'no-fill';
  let floor5Active = 'no-fill';
  let floor6Active = 'no-fill';

  switch (hover) {
    case '6':
      floor6Active = 'building-svg-path';
      break;

    case '5':
      floor5Active = 'building-svg-path';
      break;

    case '4':
      floor4Active = 'building-svg-path';
      break;

    case '3':
      floor3Active = 'building-svg-path';
      break;

    case '2':
      floor2Active = 'building-svg-path';
      break;

    case '1':
      floor1Active = 'building-svg-path';
      break;

    default:
  }

  return (
    <Fragment>
      <div className="white-bg hide-on-small-and-down tablet-height" id="backgroundSVG">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1130" className="tablet-responsive">
          <image className="absolute overflow-auto" x="60" y="-80" href="https://storage.googleapis.com/eaglecrest-public/16268SouthTower_Inventory%20(1).png" />
          <NavLink to="/SouthFloor/6">
            <path
              className={floor6Active}
              d="M 305.725 129.874 L 165.133 187.795 L 108.366 171.576 L 61.025 188.185 L 60.61 47.23 L 129.09 10.286 L 182.253 32.813 L 323.219 -43.777 L 447.365 10.286 L 511.341 -17.647 L 1480.883 440.093 L 1487.19 520.287 L 495.001 147.248 L 430.245 170.675"
            />
          </NavLink>
          <NavLink to="/SouthFloor/5">
            <path
              className={floor5Active}
              d="M 58.743 288.313 92.885 278.144 148.996 291.503 148.66 308.653 362.385 233.832 1520.375 580.414 1519.472 572.692 1511.054 570.534 1510.191 553.051 1486.018 545.713 1484.939 519.813 495.019 148.141 431.1 170.747 305.991 130.213 167.455 188.286 107.824 172.696 59.884 188.675"
            />
          </NavLink>
          <NavLink to="/SouthFloor/4">
            <polygon
              className={floor4Active}
              points="381.664 384.951 1522.566 635.966 1518.743 581.555 363.754 233.67 148.272 308.741 148.272 290.329 92.093 277.574 58.575 289.385 59.519 431.484 74.154 432.9 134.109 436.205"
            />
          </NavLink>
          <NavLink to="/SouthFloor/3">
            <polyline className={floor3Active} points="365.612 530.108 1523.686 695.577 1519.894 636.331 381.519 385.599 133.736 436.788 60.271 432.996 61.693 551.016" />
          </NavLink>
          <NavLink to="/SouthFloor/2">
            <polyline className={floor2Active} points="354.66 683.205 1526.897 754.4 1523.376 696.897 366.592 530.254 338.426 532.602 122.299 547.271 61.862 551.964 61.862 674.013 109.39 674.013" />
          </NavLink>
          <NavLink to="/SouthFloor/1">
            <polygon className={floor1Active} points="61.491 674.584 109.446 674.583 354.551 683.787 1528.404 755.962 1531.794 814.574 342.495 835.403 311.009 835.403 95.722 802.464 60.361 794.714" />
          </NavLink>
        </svg>
      </div>
      <div className="white-bg hide-on-med-and-up tablet-height" id="backgroundSVG">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720 1280" className="tablet-responsive">
          <image className="absolute overflow-auto" x="60" y="-80" href="https://storage.googleapis.com/eaglecrest-public/10124Layer%208.jpg" />
          <NavLink to="/SouthFloor/6">
            <path
              className={floor6Active}
              d="M 61.063 -43.1 L 194.086 16.393 L 272.296 -20.372 L 779.709 218.109 L 778.934 360.418 L 258.707 159.857 L 179.989 188.607 L 60.2 144.798 L 61.063 -43.1 Z"
            />
          </NavLink>
          <NavLink to="/SouthFloor/5">
            <path className={floor5Active} d="M 60.6 295.755 L 97.679 282.343 L 779.491 491.837 L 778.994 359.646 L 259.343 160.533 L 179.83 189.853 L 60.717 144.6 L 60.6 295.755 Z" />
          </NavLink>
          <NavLink to="/SouthFloor/4">
            <polygon className={floor4Active} points="58.7 462.087 82.201 454.906 780.628 613.319 778.67 491.245 97.796 283 59.933 296.057" />
          </NavLink>
          <NavLink to="/SouthFloor/3">
            <polygon className={floor3Active} points="59.794 637.46 67.627 635.501 779.401 741.328 780.707 612.725 82.206 455.4 57.4 461.928" />
          </NavLink>
          <NavLink to="/SouthFloor/2">
            <polygon className={floor2Active} points="59.301 828.729 779.343 869.203 779.343 740.601 68.44 635.5 59.3 637.458" />
          </NavLink>
          <NavLink to="/SouthFloor/1">
            <polygon className={floor1Active} points="58.5 1013.743 779.848 1001.993 779.848 869.474 59.154 829" />
          </NavLink>
        </svg>
      </div>
    </Fragment>
  );
}

export default SouthFloors;
