import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Menu from '../components/Menu';

function Footer() {
  const state = useSelector((store) => store);

  // if in embed mode, dont show footer
  // if (sessionStorage.getItem('embedMode') === 'yes') {
  //   return <></>;
  // }

  return (
    <div className="wrapper wrapper-f z-max">
      <div className="pt4 pb5 pr4 pl4 pl6-l">
        <div className="row">
          <div className="hide-on-med-and-down col l3 xl3">
            <img src="https://storage.googleapis.com/eaglecrest-public/10776TL_Logo_Colour_Full-1.png" className="ml4" alt="logo" />
          </div>
          <div className="hide-on-large-and-up col s12 m12 center-align">
            <img className="responsive-img center-align" src="https://storage.googleapis.com/eaglecrest-public/10776TL_Logo_Colour_Full-1.png" alt="logo" />
          </div>
          <div className="col s12 m6 l3 xl3 font-typo">
            <ul className="font-futura-medium">
              <li className="pv3"></li>
              <li></li>
              <li className="pv3"></li>
            </ul>
          </div>
          <div className="col s12 m12 l4 xl4"></div>
          {/* <div className="col s12 m6 l2 xl2"> */}
          {/*  <ul className="right d-flex-sm"> */}
          {/*    <Menu className="nav_grey_text" /> */}
          {/*  </ul> */}
          {/* </div> */}
          <div className="clearfix hide-on-med-and-down" />
          <div className="col s12 m6 l12 xl12 hide-on-small-only">
            <a className="right ml3" href={state.content.facebook} target="_blank" rel="noreferrer">
              <img src="https://groupcore.ca/cmscdn/ofx9zsJJOOfacebook.png" height="30px" alt="facebook" />
            </a>
            <a className="right ml3" href={state.content.twitter} target="_blank" rel="noreferrer">
              <img src="https://groupcore.ca/cmscdn/HPusI6uCOxtwitter.png" height="30px" alt="twitter" />
            </a>
            <a className="right" href={state.content.youtube} target="_blank" rel="noreferrer">
              <img src="https://groupcore.ca/cmscdn/em7AetM8Kuyoutube.png" height="30px" alt="youtube" />
            </a>
          </div>
          <div className="row hide-on-med-and-up pl0 mt3">
            <div className="col s12 tc">
              <a href={state.content.facebook} target="_blank" rel="noreferrer">
                <img src="https://groupcore.ca/cmscdn/ofx9zsJJOOfacebook.png" height="30px" alt="facebook" />
              </a>
              <a className="mr3 ml3" href={state.content.twitter} target="_blank" rel="noreferrer">
                <img src="https://groupcore.ca/cmscdn/HPusI6uCOxtwitter.png" height="30px" alt="twitter" />
              </a>
              <a href={state.content.youtube} target="_blank" rel="noreferrer">
                <img src="https://groupcore.ca/cmscdn/em7AetM8Kuyoutube.png" height="30px" alt="youtube" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
