/**
 * displays desktop version of floor SVG.
 * this links to the individual Suites that are displayed as part of the SVG
 */

import { useDispatch } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';
import CoreUtils from 'groupcore-utils';
import southFloorData from '../data/southFloors.json';
import northFloorData from '../data/northFloors.json';
import northFloorMobile from '../data/northFloorsMobile.json';
import southFloorMobile from '../data/southFloorsMobile.json';
import __ from '../../utilities/functions';
import Modal from '../../ui/Modal';
import ModalButton from '../../ui/ModalButton';
import Button from '../../ui/Button';
import Clearfix from '../../ui/Clearfix';
import config from '../../utilities/config';

function Floor({ floorNumber, position }) {
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isSmall = useMediaQuery({ query: '(max-width : 600px)' });
  // const isLarge = useMediaQuery({ query: '(max-width : 993px)' });
  // const isMediumAndDown = useMediaQuery({ query: '(max-width :992px)' });

  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const [instance, setInstance] = useState();

  const [inventoryGroupData, setInventoryGroupData] = useState({
    inventory_items: [],
  });

  let svgData;
  if (isSmall) {
    svgData = position === 'north' ? northFloorMobile : southFloorMobile;
  } else {
    svgData = position === 'north' ? northFloorData : southFloorData;
  }

  // get the right floor from json data
  const data = _.find(svgData, (floor) => {
    if (floor.floor === floorNumber) {
      return floor;
    }
  });

  const coreUtil = new CoreUtils({ projectId: config.projectId, apiToken: config.apiToken, apiUrl: config.apiUrl });

  // get details of entire floor to be passed on to the suites
  useEffect(async () => {
    __.showLoadingBar(dispatch);
    const response = await coreUtil.getGroupInventory({ groupId: data.coreGroupId });
    setInventoryGroupData(response);
    __.hideLoadingBar(dispatch);
  }, [floorNumber]);

  return (
    <div className="relative overflow-hidden">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={data.viewBox}>
        <image x={data.x} y={data.y} xlinkHref={data.image} />
        {data.suites.map((suite, i) => {
          const suiteDetails = _.find(inventoryGroupData.inventoryItems, (coreSuite) => {
            if (coreSuite.id === suite.coreInventoryItemId) {
              return coreSuite;
            }
          });

          let fillColor;
          let availability;
          switch (suiteDetails?.status) {
            case 0:
              fillColor = '#eeeeee';
              availability = 'Available';
              break;
            case 1:
              fillColor = '#9f8470';
              availability = 'Hold';
              break;
            case 2:
              fillColor = '#184f6c';
              availability = 'Sold';
              break;
            default:
              fillColor = 'none';
          }

          const suiteData = suiteDetails?.data ? JSON.parse(suiteDetails?.data) : {};
          const toolTip = `
                  <div class="tc">
                    <h6>Suite ${suiteData.name}</h6>
                      <p>
                        ${suiteData.tag}<br />
                        Layout: ${suiteData.layout} <br />
                        Area: ${suiteData.area} Sqft <br />
                        Status: ${availability}
                      </p>
                   </div>
                  `;
          return (
            <Fragment key={i}>
              <ModalButton
                className="mv3 right"
                modalId="suiteImage"
                onClick={() => {
                  setModalData({ suiteImage: suite.floorplan, suiteData });
                }}
              >
                <path d={suite.svg.points} transform={suite?.svg?.transform} fill={fillColor} opacity="0.6" data-tip={toolTip} style={{ cursor: 'pointer' }} />{' '}
              </ModalButton>
            </Fragment>
          );
        })}
      </svg>
      <Modal id="suiteImage" setInstance={setInstance}>
        <div className="tc">
          <Button route={`/Reserve/${_.upperFirst(position)}/${modalData?.suiteData?.name}`} position="left" modalInstance={instance} className="hide-on-med-and-down">
            Pricing
          </Button>

          <Clearfix />
          <h3 className="">Suite {modalData?.suiteData?.name}</h3>

          <p>
            {modalData.suiteData?.tag}
            <br />
            Layout: {modalData.suiteData?.layout} <br />
            Area: {modalData.suiteData?.area} Sqft <br />
          </p>
          <Button route={`/Reserve/${_.upperFirst(position)}/${modalData?.suiteData?.name}`} position="tc" modalInstance={instance} className="hide-on-large-and-up">
            Reserve Suite
          </Button>
          <img src={modalData?.suiteImage} alt="Loading..." style={{ width: '60%', marginTop: '-60px' }} className="hide-on-med-and-down" />
          <img src={modalData?.suiteImage} alt="Loading..." style={{ width: '100%' }} className="hide-on-large-and-up" />
        </div>
      </Modal>
    </div>
  );
}

export default Floor;
