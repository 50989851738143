/**
 */

import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import __ from '../../utilities/functions';

function BackButton() {
  const dispatch = useDispatch();

  function goBack() {
    __.redirect(dispatch, '/');
  }
  return (
    <Fragment>
      <i
        id="takeBack"
        className="medium material-icons o-70"
        onClick={() => {
          goBack();
        }}
      >
        keyboard_backspace
      </i>
    </Fragment>
  );
}

export default BackButton;
