/**
 * desktop version of the North building SVG with colored floor overlays
 */

import { NavLink } from 'react-router-dom';
import { Fragment, useState } from 'react';

function NorthFloors({ hover }) {
  let floor1Active = 'no-fill';
  let floor2Active = 'no-fill';
  let floor3Active = 'no-fill';
  let floor4Active = 'no-fill';
  let floor5Active = 'no-fill';
  let floor6Active = 'no-fill';

  switch (hover) {
    case '6':
      floor6Active = 'building-svg-path';
      break;

    case '5':
      floor5Active = 'building-svg-path';
      break;

    case '4':
      floor4Active = 'building-svg-path';
      break;

    case '3':
      floor3Active = 'building-svg-path';
      break;

    case '2':
      floor2Active = 'building-svg-path';
      break;

    case '1':
      floor1Active = 'building-svg-path';
      break;

    default:
  }
  return (
    <Fragment>
      <div className="white-bg hide-on-small-and-down tablet-height" id="backgroundSVG">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1130" className="tablet-responsive">
          <image className="absolute overflow-auto" x="0" y="-80" href="https://storage.googleapis.com/eaglecrest-public/9147NorthTower_AllFloors%20(1).jpg" />
          <NavLink to="/NorthFloor/6">
            <polygon
              className={floor6Active}
              points="695.8499755859375 327.4750061035156 695.3939819335938 276.2980041503906 715.9559936523438 274.01300048828125 714.5850219726562 246.5970001220703 769.875 244.7689971923828 791.8079833984375 248.4250030517578 792.2650146484375 271.72900390625 904.2150268554688 267.6159973144531 1104.81201171875 293.6619873046875 1104.81201171875 288.635009765625 1104.81201171875 288.635009765625 1275.60595703125 283.6090087890625 1397.35498046875 297.3169860839844 1396.2889404296875 333.87200927734375 1475.7960205078125 347.58099365234375 1472.5980224609375 422.06201171875 1129.43505859375 449.02099609375 1097.405029296875 441.6839904785156 1045.3580322265625 447.1929931640625"
            />
          </NavLink>
          <NavLink to="/NorthFloor/5">
            <polygon
              className={floor5Active}
              points="681.6300048828125 358.6629943847656 685.6619873046875 345.6709899902344 697.3099975585938 348.3590087890625 695.5180053710938 327.7510070800781 1045.85498046875 447.36700439453125 1096.927001953125 441.0950012207031 1129.1829833984375 449.6080017089844 1472.8489990234375 421.83099365234375 1472.4010009765625 484.552001953125 1069.64794921875 525.3200073242188"
            />
          </NavLink>
          <NavLink to="/NorthFloor/4">
            <polygon
              className={floor4Active}
              points="681.6300048828125 359.65899658203125 1070.0469970703125 525.8670043945312 1461.051025390625 486.8909912109375 1461.0009765625 551.35302734375 1066.9599609375 593.2160034179688 683.52197265625 402.4679870605469"
            />
          </NavLink>
          <NavLink to="/NorthFloor/3">
            <polygon
              className={floor3Active}
              points="683.6209716796875 403.364013671875 684.9650268554688 441.8919982910156 1066.6619873046875 662.7559814453125 1462.9429931640625 614.3720092773438 1460.2550048828125 552.5479736328125 1066.9100341796875 593.7639770507812 683.6710205078125 402.9159851074219"
            />
          </NavLink>
          <NavLink to="/NorthFloor/2">
            <polygon
              className={floor2Active}
              points="686.4580078125 486.6919860839844 685.56201171875 442.3389892578125 1066.363037109375 663.2039794921875 1322.1209716796875 633.3870239257812 1321.6729736328125 633.8350219726562 1320.7769775390625 695.6589965820312 1067.208984375 731.947021484375"
            />
          </NavLink>
          <NavLink to="/NorthFloor/1">
            <polygon
              className={floor1Active}
              points="686.906005859375 534.677001953125 686.010009765625 487.6369934082031 1068.155029296875 734.0380249023438 1317.1929931640625 696.2559814453125 1319.7760009765625 696.9019775390625 1316.7449951171875 776 1257.1610107421875 783.1680297851562 1241.928955078125 772.864013671875 1181.0009765625 784.5120239257812 1067.656982421875 829.760009765625"
            />
          </NavLink>
        </svg>
      </div>
      <div className="white-bg hide-on-med-and-up tablet-height" id="backgroundSVG">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720 1280" className="tablet-responsive">
          <image className="absolute overflow-auto" x="0" y="-80" href="https://storage.googleapis.com/eaglecrest-public/8739LOOKOUT_Responsive%20Design_2%20copy.jpg" />
          <NavLink to="/NorthFloor/6">
            <polygon
                className={floor6Active}
                points="1.445 178.546 41.87 176.687 32.112 174.364 32.112 134.869 112.496 131.152 145.95 136.263 147.344 170.182 311.364 164.606 615.345 207.818 615.345 198.99 721.181 194.292 719.787 437.714 651.484 429.815 597.585 427.957 527.888 435.856 0.374 253.734"
            />
          </NavLink>
          <NavLink to="/NorthFloor/5">
            <polygon
                className={floor5Active}
                points="0.764 317.501 0.133 255.01 528.468 435.54 597.272 428.597 651.557 430.49 719.098 438.065 719.729 542.217 563.817 556.104"
            />
          </NavLink>
          <NavLink to="/NorthFloor/4">
            <polygon
                className={floor4Active}
                points="554.488 654.294 0.905 373.399 0.273 317.501 563.957 557.366 720.501 542.848 719.238 646.369 562.694 656.469"
            />
          </NavLink>
          <NavLink to="/NorthFloor/3">
            <polygon
                className={floor3Active}
                points="-0.077 428.597 552.244 754.94 561.712 759.989 720.15 744.209 718.256 647 563.606 655.206 552.875 653.944 0.554 373.68"
            />
          </NavLink>
          <NavLink to="/NorthFloor/2">
            <polygon
                className={floor2Active}
                points="0.624 486.178 561.783 858.601 718.957 843.451 719.589 743.718 562.414 759.498 0.624 428.737"
            />
          </NavLink>
          <NavLink to="/NorthFloor/1">
            <polygon
                className={floor1Active}
                points="-0.007 540.534 561.151 954.617 719.589 946.411 718.957 844.153 560.52 859.302 -0.007 485.617"
            />
          </NavLink>
        </svg>
      </div>
    </Fragment>
  );
}

export default NorthFloors;
